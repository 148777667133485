import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Layout } from "../../styled/Layout";
import AppFooter from "../../../common/AppFooter";
import { RootState } from "../../../store/Store";
import { StyledButtonWrapper } from "../../styled/StyledButtonWrapper";
import { ThemedButton, InputFieldNew, Link } from "ccp-common-ui-components";
import { useForm } from "react-hook-form";

import { SubPageHeader } from "../../styled/SubPageHeader";
import { PageLoader } from "../../common/PageLoader";
import { IconCircle } from "../../common/IconCircle";
import {
  CenteredHeader,
  CenteredParagraph,
  InfoPageThemedButton,
} from "../../styled/InfoPage";
import {
  CancelLink,
  ReceiptPreferencesContainer,
  ReceiptPreferencesDescriptionBold,
  ReceiptPreferencesDescription,
  ReceiptPreferencesButtons,
  Divider,
  ReceiptPreferencesDescriptionBoldLvl2,
} from "./ReceiptPreferencesPageStyles";
import { ReceiptPreferencesCheckbox } from "./ReceiptPreferencesCheckbox";
import { getCustomerProfilePreferencesAction } from "../../../store/customer-profile-preferences/CustomerProfilePreferencesActions";
import { ReceiptPreference } from "../../../store/customer-profile-preferences/CustomerProfilePreferencesSlice";
import { getReturnToLink } from "../../../common/utils/RedirectionHelpers";
import { redirect } from "../../../common/utils/NavigationHelpers";
import { updateCustomerProfilePreferences } from "../../../api/preferences/CustomerProfilePreferencesApi";
import { validateEmail } from "./validation/validation";
import {
  createCustomerProfileEmailContact,
  updateCustomerProfileEmailContact,
  deleteCustomerProfileEmailContact,
} from "../../../api/contacts/CustomerProfileContactsApi";
import {
  onlineContent,
  email,
  instoreContent,
  paper,
  digitalReceipts,
  preferredemail,
  generateModeDescription,
  mapPreferences,
} from "../Utils/PreferencesUtils";
import { getCustomerProfileAction } from "../../../store/customer-profile/CustomerProfileActions";
import { useIsBusinessCustomer } from "../Utils/CustomerUtils";
import styled from "styled-components";

type FormData = {
  email: string;
};

const StyledOL = styled.ol`
  margin-block-start: 0.5em;
  padding-inline-start: 1em;
`;

const ReceiptPreferencesPage = () => {
  const history = useHistory();
  const [editablePreferences, setEditablePreferences] = useState<
    ReceiptPreference[]
  >([]);

  const [editableInstorePreferences, setEditableInstorePreferences] = useState<
    ReceiptPreference[]
  >([]);
  const [isOnlinePreferencesChanged, setOnlinePreferencesStatus] =
    useState<boolean>(false);
  const [isInstorePreferencesChanged, setInstorePreferencesStatus] =
    useState<boolean>(false);

  const [updatePreferencesSuccess, setUpdatePreferencesSuccess] =
    useState<boolean>(false);
  const [updatePreferencesFailed, setUpdatePreferencesFailed] =
    useState<boolean>(false);
  const { brand } = useParams<{ brand?: string }>();
  const dispatch = useDispatch();
  const {
    receiptPreferences,
    isLoadingPreferences,
    secondaryEmail,
    isShowingSecondaryEmailSection,
  } = useSelector((state: RootState) => state.customerProfilePreferences);

  const [isOnlineShoppingEmailSelected, setOnlineShoppingEmailState] =
    useState<boolean>(false);

  const customerProfileType = useSelector(
    (state: RootState) => state.customerProfile.profileFields.profileType
  );

  const isBusinessCustomer = useIsBusinessCustomer();

  const showLoader = isLoadingPreferences;

  useEffect(() => {
    setEditablePreferences(
      receiptPreferences.filter(
        (pref) =>
          pref.content === onlineContent &&
          pref.brand === brand &&
          pref.mode === email
      )
    );

    setEditableInstorePreferences(
      receiptPreferences.filter(
        (pref) =>
          pref.brand === brand &&
          pref.content === instoreContent &&
          pref.mode === paper
      )
    );
  }, [receiptPreferences, brand]);

  useEffect(() => {
    dispatch(getCustomerProfilePreferencesAction());
    if (customerProfileType === "") {
      dispatch(getCustomerProfileAction());
    }
  }, [dispatch, customerProfileType]);

  useEffect(() => {
    setOnlineShoppingEmailState(isShowingSecondaryEmailSection);
  }, [isShowingSecondaryEmailSection]);

  const changeHandler = (pref: ReceiptPreference) => {
    const clonedEditablePreferences: ReceiptPreference[] = JSON.parse(
      JSON.stringify(editablePreferences)
    );
    const targetItemIndex = clonedEditablePreferences.findIndex(
      (i) => i.name === pref.name
    );
    if (targetItemIndex !== -1) {
      clonedEditablePreferences[targetItemIndex].value = !pref.value;
      setEditablePreferences(clonedEditablePreferences);
      setOnlinePreferencesStatus(true);
    }

    setOnlineShoppingEmailState(!pref.value);
  };

  const changeInstoreHandler = (pref: ReceiptPreference) => {
    const clonedEditableInstorePreferences: ReceiptPreference[] = JSON.parse(
      JSON.stringify(editableInstorePreferences)
    );
    const targetItemIndex = clonedEditableInstorePreferences.findIndex(
      (i) => i.name === pref.name
    );
    if (targetItemIndex !== -1) {
      clonedEditableInstorePreferences[targetItemIndex].value = !pref.value;
      setEditableInstorePreferences(clonedEditableInstorePreferences);
      setInstorePreferencesStatus(true);
    }
  };

  const handleSuccessOrErrorClick = () => {
    const linkValue = getReturnToLink();

    if (linkValue !== "/") {
      return redirect(linkValue);
    }

    history.push("/");
  };

  const submitChangedPreferences = async (data: FormData) => {
    const payload = mapPreferences([
      ...(isOnlinePreferencesChanged ? editablePreferences : []),
      ...(isInstorePreferencesChanged ? editableInstorePreferences : []),
    ]);

    let isSecondaryEmailChanged = secondaryEmail.emailValue !== data.email;
    let isSecondaryEmailAlreadyExist = secondaryEmail.id !== "";

    let isContactDeleted = false;
    let isEmailUpdateSuccessful = true;

    if (isSecondaryEmailChanged && isSecondaryEmailAlreadyExist) {
      if (data.email) {
        try {
          isEmailUpdateSuccessful = await updateCustomerProfileEmailContact(
            secondaryEmail.id,
            {
              Email: data.email,
            }
          );
        } catch {
          isEmailUpdateSuccessful = false;
        }
      } else {
        isContactDeleted = true;

        payload.push({
          group: digitalReceipts,
          name: preferredemail,
          value: "",
        });
      }
    }

    if (isSecondaryEmailChanged && !isSecondaryEmailAlreadyExist) {
      if (data.email) {
        const emailCreateResult = await createCustomerProfileEmailContact({
          Email: data.email,
        });

        payload.push({
          group: digitalReceipts,
          name: preferredemail,
          value: emailCreateResult.id,
        });
      }
    }

    let isPreferencesUpdateSuccessful = true;

    if (payload.length > 0) {
      isPreferencesUpdateSuccessful = await updateCustomerProfilePreferences(
        payload
      );

      if (isPreferencesUpdateSuccessful && isContactDeleted) {
        await deleteCustomerProfileEmailContact(secondaryEmail.id);
      }
    }

    if (isPreferencesUpdateSuccessful && isEmailUpdateSuccessful) {
      setUpdatePreferencesSuccess(true);
      setUpdatePreferencesFailed(false);
    } else {
      setUpdatePreferencesSuccess(false);
      setUpdatePreferencesFailed(true);
    }
  };

  const updateSuccessMessage = (
    <>
      <IconCircle decorative={true} />
      <CenteredHeader tabIndex={-1} className="sentry-unmask">
        You're all set!
      </CenteredHeader>
      <CenteredParagraph className="sentry-unmask">
        Your receipt preferences have been updated.
      </CenteredParagraph>
      <StyledButtonWrapper>
        <InfoPageThemedButton
          onClick={() => {
            handleSuccessOrErrorClick();
          }}
          className="sentry-unmask"
        >
          Continue
        </InfoPageThemedButton>
      </StyledButtonWrapper>
    </>
  );

  const updateErrorMessage = (
    <>
      <IconCircle decorative={true} hasError={true} />
      <CenteredHeader hasError={true} tabIndex={-1} className="sentry-unmask">
        Receipt preferences not changed
      </CenteredHeader>
      <CenteredParagraph className="sentry-unmask">
        An error has occurred. Your receipt preferences have not been updated.
      </CenteredParagraph>
      <StyledButtonWrapper>
        <InfoPageThemedButton
          onClick={() => {
            handleSuccessOrErrorClick();
          }}
          className="sentry-unmask"
        >
          Continue
        </InfoPageThemedButton>
      </StyledButtonWrapper>
    </>
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const secondaryEmailInput = (
    <InputFieldNew
      id="email"
      type="email"
      aria-invalid={!!errors.email}
      aria-describedby="email-error information-collection"
      autoComplete="email"
      data-testid="email-input"
      defaultValue={secondaryEmail.emailValue}
      {...register("email", {
        required: false,
        validate: validateEmail,
      })}
      hasError={!!errors.email}
      descriptionText="For example, john.smith@gmail.com"
      errorText={
        errors.email && errors.email.message
          ? errors.email.message
          : "For example, john.smith@gmail.com"
      }
    />
  );

  return (
    <Layout>
      <SubPageHeader className="sentry-unmask">
        Receipt preferences
      </SubPageHeader>
      <PageLoader isShown={showLoader} />
      {!showLoader && (
        <ReceiptPreferencesContainer>
          {updatePreferencesSuccess &&
            !updatePreferencesFailed &&
            updateSuccessMessage}
          {!updatePreferencesSuccess &&
            updatePreferencesFailed &&
            updateErrorMessage}
          <form
            onSubmit={handleSubmit(submitChangedPreferences)}
            data-testid="manage-my-receipt-preferences-form"
            noValidate
          >
            {!(updatePreferencesSuccess || updatePreferencesFailed) && (
              <>
                <ReceiptPreferencesDescriptionBold className="sentry-unmask">
                  Instore shopping
                </ReceiptPreferencesDescriptionBold>

                <ReceiptPreferencesDescription className="sentry-unmask">
                  Choose how you&apos;d like to receive receipts.
                </ReceiptPreferencesDescription>

                {editableInstorePreferences &&
                  editableInstorePreferences.length &&
                  editableInstorePreferences.length > 0 &&
                  editableInstorePreferences.map((pref) => (
                    <ReceiptPreferencesCheckbox
                      key={pref.name}
                      id={pref.name}
                      name={pref.name}
                      testid={`test-${pref.name}`}
                      label={generateModeDescription(pref.mode)}
                      hasError={false}
                      checked={pref.value}
                      onChange={() => {
                        changeInstoreHandler(pref);
                      }}
                    />
                  ))}

                <Divider />
                {isBusinessCustomer && (
                  <>
                    <ReceiptPreferencesDescriptionBold className="sentry-unmask">
                      Online shopping
                    </ReceiptPreferencesDescriptionBold>
                    <ReceiptPreferencesDescription className="sentry-unmask">
                      Tell us how you'd like to get receipts for online orders
                    </ReceiptPreferencesDescription>
                    {editablePreferences &&
                      editablePreferences.length &&
                      editablePreferences.length > 0 &&
                      editablePreferences.map((pref) => (
                        <ReceiptPreferencesCheckbox
                          key={pref.name}
                          id={pref.name}
                          name={pref.name}
                          testid={`test-${pref.name}`}
                          label={generateModeDescription(pref.mode)}
                          hasError={false}
                          checked={pref.value}
                          onChange={() => {
                            changeHandler(pref);
                          }}
                        />
                      ))}
                    <Divider />
                  </>
                )}
                <ReceiptPreferencesDescriptionBold className="sentry-unmask">
                  Get instore receipts digitally
                </ReceiptPreferencesDescriptionBold>
                <ReceiptPreferencesDescription className="sentry-unmask">
                  Skip printing, get digital receipts for instore shopping. Find
                  all your receipts in <b>Orders & purchases</b>.
                </ReceiptPreferencesDescription>
                <StyledOL className="sentry-unmask">
                  <li>Link your Flybuys and Coles account</li>
                  <li>Disable paper receipts</li>
                  <li>
                    Scan your Coles barcode at checkout when you shop instore
                  </li>
                </StyledOL>
                {isBusinessCustomer && isOnlineShoppingEmailSelected && (
                  <>
                    <Divider />
                    <ReceiptPreferencesDescriptionBold className="sentry-unmask">
                      Send a copy of your receipt
                    </ReceiptPreferencesDescriptionBold>
                    <ReceiptPreferencesDescription className="sentry-unmask">
                      Send your online order receipts to another email address.
                    </ReceiptPreferencesDescription>
                    {secondaryEmailInput}
                  </>
                )}
                <Divider />

                <ReceiptPreferencesButtons>
                  <ThemedButton
                    data-testid="save-button"
                    type="submit"
                    aria-label="Save preference for receipt communications"
                    className="sentry-unmask"
                  >
                    Save preferences
                  </ThemedButton>
                  <CancelLink
                    tabIndex={0}
                    href={getReturnToLink()}
                    className="sentry-unmask"
                  >
                    Cancel
                  </CancelLink>
                </ReceiptPreferencesButtons>
              </>
            )}
          </form>
        </ReceiptPreferencesContainer>
      )}
      <AppFooter />
    </Layout>
  );
};

export default ReceiptPreferencesPage;
