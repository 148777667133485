import { ThemedButton } from "ccp-common-ui-components";
import { useDispatch } from "react-redux";
import { deleteAccount } from "../../store/delete-customer-profile/view/DeleteAccountSlice";

import ErrorIcon from "../../assets/icons/error.svg";
import {
  DeleteAccountStandardIcon,
  DeleteAccountSingularButton,
  DeleteAccountText,
} from "./DeleteAccountStyles";

export function DeleteAccountError() {
  const dispatch = useDispatch();

  const sendDeleteAccount = () => {
    dispatch(deleteAccount());
  };

  return (
    <>
      <DeleteAccountStandardIcon src={ErrorIcon} alt="error" />
      <h2 className="sentry-unmask">Something went wrong</h2>
      <DeleteAccountText className="sentry-unmask">
        We couldn&#39;t send your request to delete your account. Please try
        again.
      </DeleteAccountText>
      <DeleteAccountSingularButton>
        <ThemedButton onClick={sendDeleteAccount} className="sentry-unmask">
          Try again
        </ThemedButton>
      </DeleteAccountSingularButton>
    </>
  );
}
