import { useSelector } from "react-redux";
import { Redirect, useRouteMatch } from "react-router-dom";
import { RootState } from "../../store/Store";
import FlybuysErrorPage from "./FlybuysErrorPage";
import VerifyFlybuysAccount from "./VerifyFlybuysAccount";
import { SentryRoute } from "../../sentry.config";

const VerifyFlybuysAccountPage = () => {
  const { path } = useRouteMatch();

  const { isFlybuysLinkSuccess } = useSelector(
    (state: RootState) => state.flybuys
  );

  return (
    <>
      <SentryRoute exact path={`${path}/error`}>
        {typeof isFlybuysLinkSuccess !== "undefined" ? (
          <FlybuysErrorPage />
        ) : (
          <Redirect to="/" />
        )}
      </SentryRoute>
      <SentryRoute exact path={path}>
        <VerifyFlybuysAccount />
      </SentryRoute>
    </>
  );
};

export default VerifyFlybuysAccountPage;
