import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";

import { Route } from "react-router-dom";
import configuration from "./config/Configuration";

const SentryRoute = Sentry.withSentryRouting(Route);
const history = createBrowserHistory();

const initSentry = () => {
  if (configuration.sentryEnv && process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: configuration.sentryEnv,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.reactRouterV5BrowserTracingIntegration({ history }),
        Sentry.replayIntegration({
          maskAllText: true,
          maskAllInputs: true,
          unmask: [
            ".fs-unmask", // Unmask same elements as full story
            ".sentry-unmask", // Unmask specific elemnts for sentry
            "label", // Unmask input labels
          ],
        }),
        Sentry.captureConsoleIntegration({ levels: ["error"] }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      //   tracePropagationTargets: [/^https:\/\/yourserver\.io\/api/],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
    Sentry.setTag("spa-name", "profile-management-spa");
  }
};

export default initSentry;
export { history, SentryRoute };
