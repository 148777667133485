import React, { useEffect, useRef } from "react";
import { IconCircle } from "../common/IconCircle";
import { StyledButtonWrapper } from "../styled/StyledButtonWrapper";
import {
  CenteredHeader,
  CenteredParagraph,
  InfoContainer,
  InfoPageThemedButton,
} from "../styled/InfoPage";
import AppFooter from "../../common/AppFooter";
import {
  continueMessage,
  mobileNumberSuccessfulVerifiedMessage,
} from "../../common/constants/InfoMessages";
import { Layout } from "../styled/Layout";
import { useReturnUrl } from "../../common/hooks/useReturnUrl";

const redirectTimeInSeconds = 5;

interface VerificationCodeAcceptedPageProps {
  backButtonUrl: string | undefined;
}

export function VerificationCodeAcceptedPage(
  props: VerificationCodeAcceptedPageProps
) {
  const headingRef = useRef<HTMLHeadingElement>(null);
  const { handleNavigation } = useReturnUrl(props.backButtonUrl);

  const returnToGroupProfile = () => {
    handleNavigation();
  };

  useEffect(() => {
    headingRef.current?.focus();

    const redirectTimerId = setTimeout(
      returnToGroupProfile,
      redirectTimeInSeconds * 1000
    );
    return () => {
      clearTimeout(redirectTimerId);
    };
  });

  return (
    <Layout>
      <InfoContainer>
        <IconCircle decorative={true} />
        <CenteredHeader
          tabIndex={-1}
          ref={headingRef}
          className="sentry-unmask"
        >
          Code accepted
        </CenteredHeader>
        <CenteredParagraph className="sentry-unmask">
          {mobileNumberSuccessfulVerifiedMessage}
        </CenteredParagraph>
        <CenteredParagraph className="sentry-unmask">
          You’ll be redirected automatically in {redirectTimeInSeconds} seconds.
        </CenteredParagraph>
        <StyledButtonWrapper>
          <InfoPageThemedButton
            onClick={returnToGroupProfile}
            className="sentry-unmask"
          >
            {continueMessage}
          </InfoPageThemedButton>
        </StyledButtonWrapper>
      </InfoContainer>
      <AppFooter />
    </Layout>
  );
}
