import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../../common/hooks/useQuery";

import {
  UnsubscribeButtons,
  UnsubscribeContainer,
  UnsubscribeContent,
  UnsubscribeText,
  UnsubscribeNoButton,
  UnsubscribeTitleHeader,
  UnsubscribeHeaderLogo,
  UnsubscribeHeader,
} from "./UnsubscribeStyles";
import colesLogo from "../../../assets/logos/coles-logo.svg";

import ErrorPage from "../../../common/error/ErrorPage";
import { PageLoader } from "../../common/PageLoader";
import { ThemedButton } from "ccp-common-ui-components";
import {
  unsubscribePreferences,
  getTargetPreferenceDetails,
} from "../../../api/unsubscribe/UnsubscribeApi";
import { getReturnToLink } from "../../../common/utils/RedirectionHelpers";
import { redirect } from "../../../common/utils/NavigationHelpers";

interface UnsubscribePersonalizationData {
  FirstName: string;
  Email: string;
}

const generateModeDescription = (mode: string) => {
  switch (mode) {
    case "sms":
      return "SMS";
    case "email":
      return `Email`;
    default:
      return mode;
  }
};

const getBrandSpecificDescriptor = (brand?: string) => {
  switch (brand) {
    case "cexp":
      return "Coles Express";
    case "supermarket":
      return "Coles Supermarkets";
    default:
      return "Coles Supermarkets";
  }
};

const UnsubscribePage = () => {
  const [showUnsubscribeOptions, setShowUnsubscribeOptions] =
    useState<boolean>(true);
  const [showPendingLoader, setShowPendingLoader] = useState<boolean>(false);
  const [showUnsubscribeSuccess, setShowUnsubscribeSuccess] =
    useState<boolean>(false);
  const [targetBrand, setTargetBrand] = useState<string>("");
  const [targetMode, setTargetMode] = useState<string>("");

  const history = useHistory();
  const queryParams = useQuery();

  const profileDataRaw = queryParams.get("x");
  const personalizationDataRaw = queryParams.get("y");
  const targetPreferenceRaw = queryParams.get("z");

  const updateTargetPreferenceDetails = async (hash: string) => {
    setShowPendingLoader(true);
    const res = await getTargetPreferenceDetails(hash);
    if (res && res.length) {
      const splitRes = res.split(".");
      setTargetBrand(splitRes[1]);
      setTargetMode(splitRes[5]);
    }
    setShowPendingLoader(false);
  };

  useEffect(() => {
    if (
      targetPreferenceRaw &&
      targetPreferenceRaw.length &&
      targetPreferenceRaw.length > 0
    ) {
      updateTargetPreferenceDetails(targetPreferenceRaw);
    }
  }, [targetPreferenceRaw]);

  const personalizationDataDecoded: string | null = personalizationDataRaw
    ? atob(personalizationDataRaw)
    : null;
  const personalizationDataParsed: UnsubscribePersonalizationData | null =
    JSON.parse(personalizationDataDecoded || "");

  const handleSuccessOrErrorClick = () => {
    const linkValue = getReturnToLink();

    if (linkValue !== "/") {
      return redirect(linkValue);
    }

    history.push("/");
  };

  const sendUnsubscribe = async () => {
    setShowPendingLoader(true);
    const unsubscribeRequestResult = await unsubscribePreferences({
      profileData: profileDataRaw || "",
      targetPreference: targetPreferenceRaw || "",
    });

    setShowPendingLoader(false);
    if (unsubscribeRequestResult) {
      setShowUnsubscribeOptions(true);
      setShowUnsubscribeSuccess(true);
    } else {
      setShowUnsubscribeOptions(true);
      setShowUnsubscribeSuccess(false);
    }
  };

  return (
    <UnsubscribeContainer>
      <UnsubscribeHeader>
        <UnsubscribeHeaderLogo
          src={colesLogo}
          alt="Coles logo"
          width="123"
          height="23"
        />
      </UnsubscribeHeader>
      <UnsubscribeContent>
        {showUnsubscribeOptions ? (
          <>
            <PageLoader isShown={showPendingLoader} />
            {!showPendingLoader && (
              <>
                {showUnsubscribeSuccess ? (
                  <>
                    <UnsubscribeTitleHeader className="sentry-unmask">{`You will be missed!`}</UnsubscribeTitleHeader>
                    <UnsubscribeText>
                      Hi
                      <strong>
                        {` ${
                          personalizationDataParsed &&
                          personalizationDataParsed.FirstName
                        }`}
                      </strong>
                      ,
                    </UnsubscribeText>
                    <UnsubscribeText>
                      We will no longer send{" "}
                      {`${getBrandSpecificDescriptor(targetBrand)}`}{" "}
                      communications via{" "}
                      {`${generateModeDescription(targetMode)}`} to{" "}
                      <strong>{`${
                        personalizationDataParsed &&
                        personalizationDataParsed.Email
                      }`}</strong>
                      .
                    </UnsubscribeText>
                    <UnsubscribeText className="sentry-unmask">
                      You will still receive non-promotional communications with
                      information directly related to your account and
                      purchases.
                    </UnsubscribeText>
                    <UnsubscribeButtons>
                      <ThemedButton
                        onClick={() => {
                          handleSuccessOrErrorClick();
                        }}
                        className="sentry-unmask"
                      >
                        Done
                      </ThemedButton>
                    </UnsubscribeButtons>
                  </>
                ) : (
                  <>
                    <UnsubscribeTitleHeader className="sentry-unmask">{`We'll be sad to see you go!`}</UnsubscribeTitleHeader>
                    <UnsubscribeText>
                      Hi
                      <strong>
                        {` ${
                          personalizationDataParsed &&
                          personalizationDataParsed.FirstName
                        }`}
                      </strong>
                      ,
                    </UnsubscribeText>
                    <UnsubscribeText>
                      Are you sure you want to leave?
                    </UnsubscribeText>
                    <UnsubscribeText>
                      By unsubscribing,
                      <strong>{` ${
                        personalizationDataParsed &&
                        personalizationDataParsed.Email
                      } `}</strong>
                      will no longer receive
                      {` ${getBrandSpecificDescriptor(targetBrand)} `}
                      communications via
                      {` ${generateModeDescription(targetMode)}`}.
                    </UnsubscribeText>
                    <UnsubscribeButtons>
                      <ThemedButton
                        onClick={() => {
                          sendUnsubscribe();
                        }}
                        className="sentry-unmask"
                      >
                        Yes, I want to leave
                      </ThemedButton>
                      <UnsubscribeNoButton
                        onClick={() => {
                          handleSuccessOrErrorClick();
                        }}
                        className="sentry-unmask"
                      >
                        No, I want to stay
                      </UnsubscribeNoButton>
                    </UnsubscribeButtons>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <ErrorPage />
        )}
      </UnsubscribeContent>
    </UnsubscribeContainer>
  );
};

export default UnsubscribePage;
