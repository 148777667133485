import styled from "styled-components";
import { IconCircle } from "../common/IconCircle";
import { headers } from "../../common/styles/RocketColours";
import { useEffect } from "react";
import { dataLayerVerifyEmailFailureImpression } from "../../common/utils/tracking/verifyEmailPageTracking";
import { getQueryParameter } from "../../common/utils/NavigationHelpers";

const FailureH2 = styled.h2`
  font-family: "Avant Garde";
  color: ${headers.error};
`;

const FailureText = styled.p`
  color: ${headers.black700};
`;

export function EmailVerifiedFailure() {
  const returnTo =
    getQueryParameter("returnTo") ||
    `${window.location.protocol}//${window.location.host}`;

  useEffect(() => {
    dataLayerVerifyEmailFailureImpression(returnTo);
  }, [returnTo]);

  return (
    <div data-testid="email-verified-failure">
      <IconCircle decorative={true} hasError={true} />
      <FailureH2 className="sentry-unmask">Link has expired</FailureH2>
      <FailureText className="sentry-unmask">
        Sorry! This link has already been accessed or it has expired
      </FailureText>
    </div>
  );
}
