import { useQuery } from "../../common/hooks/useQuery";
import { redirect } from "../../common/utils/NavigationHelpers";
import { ResultPage } from "../common/ResultPage";
import { SubPageHeader } from "../styled/SubPageHeader";

const FlybuysErrorPage = () => {
  const returnUrl = useQuery().get("returnTo") || "/";

  const continueToUrl = () => {
    redirect(returnUrl);
  };

  return (
    <>
      <SubPageHeader className="sentry-unmask">Link my flybuys</SubPageHeader>
      <ResultPage
        hasError={true}
        header="Something went wrong"
        description="We are unable to link your Flybuys account right now. Please try again later."
        buttonText="Continue"
        action={continueToUrl}
      ></ResultPage>
    </>
  );
};

export default FlybuysErrorPage;
