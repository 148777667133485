import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../../store/Store";
import plusIcon from "../../../assets/icons/plus-circle.svg";
import {
  setFormVisibility,
  clearAddressNotifications,
} from "../../../store/add-address/AddAddressSlice";

const AddAddressBookButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  height: 36px;
  width: 36px;
`;

const PlusImage = styled.img`
  height: 36px;
  width: 36px;
  color: black;
`;

export function AddAddressButtonWhenHasAddresses() {
  const { addresses } = useSelector(
    (state: RootState) => state.customerProfileAddress
  );
  const hasAddresses = addresses.length > 0;
  const dispatch = useDispatch();
  const { showAddAddressForm } = useSelector(
    (state: RootState) => state.addAddress
  );
  return (
    <>
      {hasAddresses && !showAddAddressForm && (
        <AddAddressBookButton
          className="sentry-unmask"
          onClick={() => {
            dispatch(setFormVisibility(true));
            dispatch(clearAddressNotifications());
          }}
        >
          <PlusImage src={plusIcon} alt="add-address" />
        </AddAddressBookButton>
      )}
    </>
  );
}
