import { useEffect, useRef } from "react";
import redCircle from "../../assets/icons/red-circle.svg";
import { ThemedButton } from "ccp-common-ui-components";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearPasswordDetails } from "../../store/update-password/UpdatePasswordSlice";
import AppFooter from "../../common/AppFooter";
import { Layout } from "../styled/Layout";
import {
  ButtonContainer,
  CenteredHeader,
  CenteredParagraph,
  ChangePasswordErrorContainer,
  StyledIconWrapper,
  StyledStatusIcon,
} from "./ChangePasswordFailedPageStyles";

export function ChangePasswordFailedPage() {
  const headingRef = useRef<HTMLHeadingElement>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  function handleClick() {
    history.push("/change-password");
  }
  useEffect(() => {
    dispatch(clearPasswordDetails());
  }, [dispatch]);

  useEffect(() => {
    headingRef.current?.focus();
  }, []);

  return (
    <Layout>
      <ChangePasswordErrorContainer>
        <StyledIconWrapper aria-hidden>
          <StyledStatusIcon src={redCircle} alt="" />
        </StyledIconWrapper>
        <CenteredHeader
          tabIndex={-1}
          ref={headingRef}
          className="sentry-unmask"
        >
          Password not changed
        </CenteredHeader>
        <CenteredParagraph className="sentry-unmask">
          Sorry! Something went wrong when trying to change your password.
          Please try again.
        </CenteredParagraph>
        <ButtonContainer>
          <ThemedButton onClick={() => handleClick()} className="sentry-unmask">
            Try changing my password again
          </ThemedButton>
        </ButtonContainer>
      </ChangePasswordErrorContainer>
      <AppFooter />
    </Layout>
  );
}
